// URLFieldComponent.jsx
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, CircularProgress, FormControl } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const URLFieldComponent = ({
  label,
  required = false,
  submitted = false,
  value,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (submitted && required && !value) {
      setError(true);
      setErrorMessage('This field is required');
    } else {
      setError(false);
      setErrorMessage('');
    }
  }, [submitted, required, value]);

  useEffect(() => {
    if (value && !validate(value)) {
      setError(true);
      setErrorMessage('Please enter a valid URL (starting with http:// or https://)');
    } else {
      setError(false);
      setErrorMessage('');
    }
  }, [value]);

  const validate = (url) => {
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})$/;
    return urlRegex.test(url);
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const endAdornment = useMemo(() => (
    <InputAdornment position="end">
      {loading && <CircularProgress size={20} />}
      {!loading && !error && value && <CheckCircleOutlineIcon color="success" />}
      {!loading && error && <ErrorOutlineIcon color="error" />}
    </InputAdornment>
  ), [loading, error, value]);

  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        variant="outlined"
        required={required}
        value={value}
        onChange={handleChange}
        error={error}
        helperText={errorMessage}
        InputProps={{
          endAdornment: endAdornment
        }}
      />
    </FormControl>
  );
};

URLFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  submitted: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default URLFieldComponent;
