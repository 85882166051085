import React from 'react';

const UTMGenerator = ({ utmParams, children }) => {
  // Function to get label from parameter if it's an object with a non-empty label, otherwise return the string value or null
  const getParamValue = (param) => {
    if (typeof param === 'object' && param !== null && param.label) {
      return param.label;
    }
    return (typeof param === 'string' && param.trim() !== '') ? param : null;
  };

  // Destructure the utmParams to get the individual UTM values
  const {
    landingPageUrl,
    utm_source,
    utm_medium,
    utm_objective,
    utm_campaign,
    utm_content,
    utm_term,
    utm_date
  } = utmParams;

  let utm = '';
  if (landingPageUrl && utm_source && utm_medium && utm_objective && utm_campaign) {
    // Combine utm_objective and utm_campaign
    const campaignName = `${getParamValue(utm_objective)}_${getParamValue(utm_campaign)}`;
    // Combine utm_term and utm_date if both are provided, else just use utm_term
    const termAndDate = utm_date ? `${getParamValue(utm_term)}_${getParamValue(utm_date)}` : getParamValue(utm_term);
    
    // Construct the UTM parameters with explicit encoding
    const utmParts = [
      `utm_source=${encodeURIComponent(getParamValue(utm_source))}`,
      `utm_medium=${encodeURIComponent(getParamValue(utm_medium))}`,
      `utm_campaign=${encodeURIComponent(campaignName)}`,
      utm_content && `utm_content=${encodeURIComponent(getParamValue(utm_content))}`,
      utm_term && `utm_term=${encodeURIComponent(termAndDate)}`
    ].filter(Boolean).join('&'); // Join the parts with '&' and filter out any undefined or null values

    utm = `${landingPageUrl}?${utmParts}`;
  }

  // Render children with the generated UTM
  return <>{children(utm)}</>;
};

export default UTMGenerator;
