// components/Footer/FooterComponent.jsx

import React from 'react';
import styles from './FooterComponent.module.css'; // Ensure the CSS module is correctly linked

const FooterComponent = () => {
  return (
    <footer className={styles.footer}>
      Powered by <a href="https://utmsmartmanager.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>UTM Smart Manager</a> - the best campaign URL builder for marketing teams.
    </footer>
  );
};

export default FooterComponent;
