export const dropdownDependencies = {
  sourceToMedium: {
    facebook: [ 'paid_social', 'social_organic', 'influencer'],
    instagram: [ 'paid_social', 'social_organic', 'influencer'],
    linkedin: [ 'paid_social', 'social_organic', 'influencer'],
    snapchat: [ 'paid_social', 'social_organic', 'influencer'],
    twitter: [ 'paid_social', 'social_organic', 'influencer'],
    tiktok: [ 'paid_social', 'social_organic', 'influencer'],
    google: ['cpc', 'ppc', 'display'],
    // other specific mappings...
    default: [
      'paid_social', 'social_organic', 'influencer', 'cpc', 'affiliate',
      'banner', 'blog', 'direct', 'display', 'ebook', 'email', 'feed',
      'partner', 'retargeting', 'ppc', 'press', 'pdf', 'widget'
    ], // Use labels directly
  },
  mediumToObjectives: {
    cpc: ['AW', 'CO', 'KO'],
    // other mappings...
    default: ['AW', 'CO', 'KO'], // Use labels directly
  },
  // ... other dependencies for different dropdowns
};
