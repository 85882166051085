const createOption = (label, hint = '') => ({ label, hint });

// Dropdown options for Source
export const dropdownOptions1 = [
  'bing', 'constantcontact', 'duckduckgo', 'facebook', 'google',
  'instagram', 'linkedin', 'pandora', 'snapchat', 'spotify',
  'twitter', 'tiktok', 'yahoo', 'event'
].map(label => createOption(label));

// Dropdown options for Medium
export const dropdownOptions2 = [
  'paid_social', 'social_organic', 'influencer', 'cpc', 'affiliate',
  'banner', 'blog', 'direct', 'display', 'ebook', 'email', 'feed',
  'partner', 'retargeting', 'ppc', 'press', 'pdf', 'widget'
].map(label => createOption(label));

// Dropdown options for Objective
export const dropdownOptions3 = [
  'AW', 'CO', 'KO'
].map(label => {
  // Here you can define hints or additional data if needed
  const hints = { 'AW': 'Awareness', 'CO': 'Consideration', 'KO': 'Conversion' };
  return createOption(label, hints[label]);
});

// Dropdown options for Date
export const dropdownOptions4 = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
].map(label => {
  // Mapping labels to hints for months
  const monthHints = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'Jun',
    '07': 'Jul', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  return createOption(label, monthHints[label]);
});
