import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PublicIcon from '@mui/icons-material/Public';
import styles from './NavbarComponent.module.css';

function NavbarComponent() {
  return (
    <AppBar position="static" className={styles.navbar} elevation={1}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <a href="https://www.utmsmartmanager.com" target="_blank" rel="noopener noreferrer" className={styles.logoLink}>
            <img src="/utmlogo.png" alt="Logo" width={60} height={60} />
          </a>
          <Typography variant="h6" sx={{ marginLeft: 1, color: 'text.primary' }}>
            Smart Manager
          </Typography>
        </Box>
		<Button
		  startIcon={<PublicIcon />}
		  sx={{
		    color: '#4285F4',
		    borderColor: '#4285F4',
		    textTransform: 'none',
		    borderRadius: '4px',
		    padding: '6px 16px',
		    border: '1px solid',
		    marginRight: '16px',
		    height: '36px',
		  }}
		>
		  Public
		</Button>
        <a 
          href="https://utmsmartmanager.com/get-started/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className={styles.signUpLink}
        >
		<Button
		  variant="contained"
		  sx={{
		    textTransform: 'none',
		    borderRadius: '4px',
		    padding: '6px 16px',
		    backgroundColor: '#4285F4',
		    color: '#FFFFFF',
		    height: '36px',
		    boxShadow: 'none',
		    '&:hover': {
		      backgroundColor: 'desired-hover-color'
		    }
		  }}
		>
		  Sign Up
		</Button>
        </a>
      </Toolbar>
    </AppBar>
  );
}

export default NavbarComponent;
