import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, Avatar } from '@mui/material';
import styles from './FormComponent.module.css';
import URLFieldComponent from '../Fields/URLFieldComponent'; 
import DropdownFieldComponent from '../Fields/DropdownFieldComponent'; 
import TextFieldComponent from '../Fields/TextFieldComponent'; 
import UTMGenerator from './UTMGenerator'; 
import UTMViewer from './UTMViewer'; 
import { dropdownOptions1, dropdownOptions2, dropdownOptions3, dropdownOptions4 } from './dropdownOptions'; 
import { dropdownDependencies } from './dropdownDependencies'; // Ensure this file is created with appropriate mappings
import UTMContent from './UTMContent';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';

const FormComponent = () => {
  const [landingPageUrl, setLandingPageUrl] = useState('');
  const [dropdownValue1, setDropdownValue1] = useState(null);
  const [dropdownValue2, setDropdownValue2] = useState(null);
  const [dropdownValue3, setDropdownValue3] = useState(null);
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [dropdownValue4, setDropdownValue4] = useState(null);

  const [filteredDropdownOptions2, setFilteredDropdownOptions2] = useState(dropdownOptions2);
  const [filteredDropdownOptions3, setFilteredDropdownOptions3] = useState(dropdownOptions3);

useEffect(() => {
  // Map the labels to their full option objects for Medium
  const mediumOptions = dropdownDependencies.sourceToMedium[dropdownValue1?.label] || dropdownDependencies.sourceToMedium.default;
  const filteredMediumOptions = dropdownOptions2.filter(option => mediumOptions.includes(option.label));
  setFilteredDropdownOptions2(filteredMediumOptions);

  // Reset dropdownValue2 if the current selection is no longer valid for the new source
  if (!filteredMediumOptions.some(option => option.label === dropdownValue2?.label)) {
    setDropdownValue2(null);
  }

  // Map the labels to their full option objects for Objective
  const objectiveOptions = dropdownDependencies.mediumToObjectives[dropdownValue2?.label] || dropdownDependencies.mediumToObjectives.default;
  const filteredObjectiveOptions = dropdownOptions3.filter(option => objectiveOptions.includes(option.label));
  setFilteredDropdownOptions3(filteredObjectiveOptions);

  // Reset dropdownValue3 if the current selection is no longer valid for the new medium
  if (!filteredObjectiveOptions.some(option => option.label === dropdownValue3?.label)) {
    setDropdownValue3(null);
  }
  }, [dropdownValue1, dropdownValue2, dropdownValue3?.label]); // Updated dependency array


  const utmParams = {
    landingPageUrl,
    utm_source: dropdownValue1,
    utm_medium: dropdownValue2,
    utm_objective: dropdownValue3,
    utm_campaign: textFieldValue1,
    utm_content: textFieldValue2,
    utm_term: textFieldValue3,
    utm_date: dropdownValue4,
  };

 // Helper text for disabled dropdowns
  const mediumDisabledText = 'Please select a source first';
  const objectiveDisabledText = 'Please select a medium first';

  return (
    <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
      <CardHeader
        avatar={
          <Avatar 
            alt="Google UTM" 
            src="/GoogleUTM.png" 
            sx={{ width: 100, height: 100, borderRadius: 3 }}
          />
        }
        title={<Typography variant="h4">Google Campaign UTM Builder</Typography>}
        subheader={<Typography variant="body1">Use this form to build your Campaign URL (or Google UTM)</Typography>}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <form>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="caption" sx={{ color: 'gray', textAlign: 'center', mt: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
              <span style={{ fontWeight: 'bold' }}>Form settings:</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardCapslockIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} /> 
                no caps allowed, 
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <SpaceBarIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} />
                spaces as "-" (hyphens)
              </span>
            </Typography>

            <URLFieldComponent 
              label="Landing page URL" 
              required={true} 
              value={landingPageUrl}
              onChange={setLandingPageUrl}
            />
            <Box display="flex" flexDirection="row" gap={2}>
              <DropdownFieldComponent 
                label="Source" 
                options={dropdownOptions1} 
                value={dropdownValue1} 
                onChange={setDropdownValue1}
                required={true}
                hint="Click to select your source"
                helperText="The referrer (i.e. facebook, google)"
                includeOther={true}
              />
              <DropdownFieldComponent 
                label="Medium" 
                options={filteredDropdownOptions2} 
                value={dropdownValue2} 
                onChange={setDropdownValue2}
                required={true}
                hint={!dropdownValue1 ? mediumDisabledText : "Click to select your medium"}
                helperText={!dropdownValue1 ? mediumDisabledText : "Marketing medium (i.e. display, video, cpc)"}
                includeOther={false}
                disabled={!dropdownValue1}
                //hint={!dropdownValue1 ? mediumDisabledText : "Click to select your medium"}
                //helperText={!dropdownValue1 ? mediumDisabledText : "Marketing medium (i.e. display, video, cpc)"}
              />
              <DropdownFieldComponent 
                label="Objective" 
                options={filteredDropdownOptions3} 
                value={dropdownValue3} 
                onChange={setDropdownValue3}
                required={true}
                hint={!dropdownValue2 ? objectiveDisabledText : "Click to select your objective"}
                helperText={!dropdownValue2 ? objectiveDisabledText : "Abbreviated objective (i.e. awareness)"}

                includeOther={false}
                disabled={!dropdownValue2}
                //hint={!dropdownValue2 ? objectiveDisabledText : "Click to select your objective"}
                //helperText={!dropdownValue2 ? objectiveDisabledText : "Abbreviated objective (i.e. awareness)"}

              />
            </Box>
            <Box display="flex" flexDirection="row" gap={2}>
              <TextFieldComponent
                label="Campaign Name"
                value={textFieldValue1}
                onChange={setTextFieldValue1}
                required={true}
                hint="Type your campaign name"
                helperText="Unique campaign identifier"
              />
              <TextFieldComponent
                label="Adset Name"
                value={textFieldValue2}
                onChange={setTextFieldValue2}
                hint="Type your Adset name"
                helperText="Group of ads (aka utm content)"
              />
              <TextFieldComponent
                label="Ad Name"
                value={textFieldValue3}
                onChange={setTextFieldValue3}
                hint="Type your ad name"
                helperText="Unique ad name (aka utm term)"
              />
              <DropdownFieldComponent 
                label="Date" 
                options={dropdownOptions4} 
                value={dropdownValue4} 
                onChange={setDropdownValue4}
                hint="Select your release month"
                helperText="Your ad's release month"
              />
            </Box>

            <UTMGenerator utmParams={utmParams}>
              {(utm) => (
                <div className={styles.utmViewerWrapper}>
                  <UTMViewer utm={utm} />
                </div>
              )}
            </UTMGenerator>
          </Box>
        </form>
      </CardContent>
      <UTMContent />
    </Card>
  );
};

export default FormComponent;
