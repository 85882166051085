// TextFieldComponent.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControl } from '@mui/material';

const TextFieldComponent = ({
  label,
  value,
  onChange,
  hint,
  helperText,
  maxLength,
  required,
  submitted,
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(helperText || '');

  useEffect(() => {
    if (submitted && required && !value) {
      setError(true);
      setErrorMessage('This field is required');
    } else {
      setError(false);
      setErrorMessage(helperText || '');
    }
  }, [value, required, submitted, helperText]);

  useEffect(() => {
    if (maxLength && value.length > maxLength) {
      setError(true);
      setErrorMessage(`Character limit of ${maxLength} exceeded`);
    }
  }, [value, maxLength]);

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Replace spaces with hyphens
    inputValue = inputValue.replace(/ /g, '-');

    // Convert to lowercase
    inputValue = inputValue.toLowerCase();

    // Apply character limit
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    onChange(inputValue);
  };

  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        value={value}
        onChange={handleChange}
        placeholder={hint || ''}
        helperText={errorMessage}
        error={error}
        variant="outlined"
        fullWidth
        required={required}
      />
    </FormControl>
  );
};

TextFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hint: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  submitted: PropTypes.bool,
};

export default TextFieldComponent;
