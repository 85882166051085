import React from 'react';
import { Helmet } from 'react-helmet';
import FormComponent from '../components/Form/FormComponent';

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Google UTM Campaign Builder</title>
        <meta name="description" content="Easily build Campaign URLs with enforced UTM rules for your marketing campaigns with our Google Campaign URL Builder. Track your marketing efforts effectively in Google Analytics." />
        <meta name="keywords" content="Google URL Builder, UTM Builder, UTM, Campaign URL Builder, Campaign UTM Builder, Google Analytics, UTM Parameters, Google UTM, Public Google Campaign URL Builder, Easy Google UTM Builder, Best Google UTM Builder, Google Analytics URL Generator, Google Analytics Campaign Tracking, Google Analytics UTM Tracking, URL Parameter Builder for Google, Google Campaign URL Parameters, Google Analytics UTM Parameters, Google UTM Generator, Google Campaign Builder, Google Analytics Link Tracking, Google Analytics Campaign Builder, Google Analytics UTM Codes, UTM Builder for Google Analytics, URL Builder for Google Analytics, UTM Code Generator Google Analytics, UTM Tagging for Google Analytics, UTM Builder Online Google, Google Analytics Tracking Codes, Google Analytics Tracking URLs, Google Analytics Campaign URLs, How to Build Google Analytics URLs, Google Analytics URL Best Practices, Google Analytics UTM Best Practices, Google Analytics Campaign Link Builder, Create UTM Google Analytics, Google Analytics URL Tagging, Google Analytics Link Builder, Google Analytics Campaign URL Generator, Google Analytics UTM URL Builder, Google Analytics URL Creation, UTM and URL for Google Analytics" />
        <meta property="og:title" content="Google Campaign URL Builder" />
        <meta property="og:description" content="Easily build campaign URL parameters for your Google campaigns with our Google URL (UTM) Builder. Track your marketing efforts effectively in Google Analytics." />
        <meta property="og:type" content="website" />
        {/* Additional tags as required */}
      </Helmet> 
      <FormComponent />

    </div>
  );
};

export default Home;
