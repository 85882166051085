import React from 'react';
import styles from './UTMContent.module.css';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';

const URLStructure = () => {
  return (
    <Container style={{ padding: '0px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '16px 0', flexWrap: 'wrap' }}>
        <span style={{ marginRight: '8px', fontWeight: 'bold' }}>Form's URL structure:</span>
        <Chip label="Landing Page URL" variant="outlined" style={{ backgroundColor: '#e0f7fa', marginRight: '8px' }} />
        <span>?</span>
        <span>utmsource=</span>
        <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2', margin: '0 8px' }} />
        <span>&utm_medium=</span>
        <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7', margin: '0 8px' }} />
        <span>&utmcampaign=</span>
        <Chip label="objective" variant="outlined" style={{ backgroundColor: '#c8e6c9', margin: '0 8px' }} />
        <span>_</span>
        <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9', margin: '0 8px' }} />
        <span>&utm_content=</span>
        <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc', margin: '0 8px' }} />
        <span>&utm_term=</span>
        <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb', margin: '0 8px'  }} />
        <span>_</span>
        <Chip label="date" variant="outlined" style={{ backgroundColor: '#bbdefb', margin: '0 8px'  }} />

      </div>
    </Container>
  );
};

const UTMContent = () => {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Google Campaign URL (UTM) Parameters Explained</h1>
        <p className={styles.subtitle}>
          This table gives a detailed explanation and example of each Google Analytics UTM campaign parameter. Learn how this best-in-class Google Analytics UTM builder empowers marketers to create links that adhere to best practices, ensuring precise tracking and measurement of your digital marketing campaign performance directly within{' '}
          <a href="https://analytics.google.com/analytics/web/" target="_blank" rel="noopener noreferrer" className={styles.link}>
            Google Analytics
          </a>
          .
        </p>
      </header>

      <URLStructure />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Parameter</th>
            <th className={styles.th}>Required</th>
            <th className={styles.th}>Example</th>
            <th className={styles.th}>Description</th>
          </tr>
        </thead>
        <tbody>

          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Source
              <div>
                <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>google, facebook, instagram</td>
            <td className={styles.td}>Where your traffic is coming from.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Medium
              <div>
                <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>cpc, banner, display</td>
            <td className={styles.td}>What type of medium the link is used upon.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              URL Objective
              <div>
                <Chip label="objective" variant="outlined" style={{ backgroundColor: '#c8e6c9' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>awareness (aw), consideration (co), conversion (ko)</td>
            <td className={styles.td}>The goal of the URL (Note: Objective is combined with Campaign (ie "objective_campaign") in UTM Campaign Name).</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign
              <div>
                <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>holiday_sale, summer, new_release</td>
            <td className={styles.td}>The specific promotion or strategy.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Ad Set Name
              <div>
                <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>mens_shoes, genz</td>
            <td className={styles.td}>Known as UTM Content. A sub-group or variant within a campaign.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Ad Name
              <div>
                <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>blue, family_picture</td>
            <td className={styles.td}>Known as UTM Term. Use clear, descriptive terms for the unique name (Note: Ad Name is combined with Date (ie "adname_date") in UTM Term).</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Date
              <div>
                <Chip label="date" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>01 (January)</td>
            <td className={styles.td}>The release month of the UTM for improved segmentation.</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.subtitle}>
        To learn more about UTM Marketing campaig best practices, visit{' '}
        <a href="https://utmsmartmanager.com/how-to-build-store-and-track-utm-campaign-links-like-a-master-digital-marketer/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          How to Build, Store, and Track UTM Campaign links like a master digital marketer
        </a>{' '}
      </p>
      <p className={styles.caption}>
      About:{' '} 
              <a href="https://utmsmartmanager.com/why-is-utm-smart-manager-best/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          UTM Smart Manager
          </a>{' '} 
          is the best form-builder and campaign tracker for Fortune 5000 marketing teams. With pre-defined naming conventions, enforced taxonomy rules, and world-class link tracking, Smart Manager helps digital marketers optimize their marketing ROI per activity (ie Campaign URL). If you're an Adobe marketer, you can also click here for{' '}
              <a href="https://adobe.utmsmartmanager.com/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          the best Adobe CID Builder (or Adobe UTM Builder).
          </a>
      </p>  

    </section>
  );
};

export default UTMContent;
